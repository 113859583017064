<template>
  <div class="relative-position">
    <div class="background"/>
    <div class="content row absolute full-width q-pa-xl">
      <div class="col-auto">
        <AppCard
          :title="$t('title.myAffiliateId')"
          class="dash-affiliate-id"
          header-color="bg-primary"
          body-class="q-ma-lg"
          title-class="text-18-600 q-pl-md flex full-width justify-between items-center"
          bordered
        >
          <div class="row">
            <div class="col">
              <div
                v-t="'label.affiliateLink'"
                class="text-14-400 text-grey"
              />
              <div class="text-18-400">{{ account.affiliateJoinLink }}</div>
            </div>
            <div class="col-auto flex items-center">
              <q-icon
                name="fl:duplicate"
                color="grey-7"
                size="20px"
                class="cursor-pointer"
                @click="copyCode(account.affiliateJoinLink)"
              />
            </div>
          </div>
        </AppCard>
      </div>
      <div class="col q-pl-lg">
        <div class="table-top row bg-white">
          <div class="col"/>
          <div :class="['col-auto row flex items-center q-mx-lg', { 'q-my-lg': !showSearch }]">
            <AppInput
              v-if="showSearch"
              v-model.trim="query"
              class="q-mr-lg"
              outlined
            />
            <q-icon
              name="fl:search"
              color="black"
              size="20px"
              class="cursor-pointer"
              @click="showSearch = !showSearch"
            />
          </div>
        </div>
        <q-table
          class="table"
          :data="affiliatesData"
          :columns="columns"
          row-key="name"
          hide-pagination
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td
                key="mail"
                :props="props"
                class="q-pl-lg text-16-600"
              >
                <q-icon
                  name="fl:user"
                  color="grey"
                  class="q-mr-md q-ml-lg"
                  size="20px"
                />
                {{ props.row.email.current }}
              </q-td>
              <q-td
                key="id"
                :props="props"
                class="text-16-400 text-grey"
              >
                {{ props.row.id }}
              </q-td>
              <q-td
                key="user"
                :props="props"
                class="text-16-400 text-grey"
              >
                {{ props.row.displayName }}
              </q-td>
              <q-td
                key="time"
                :props="props"
                class="text-16-400 text-grey"
              >
                {{ props.row.createdAt.formatted }}
              </q-td>
              <q-td
                key="actions"
                class="actions"
                :props="props"
              >
                <q-icon
                  name="fl:vert-setting"
                  color="black"
                  size="18px"
                  class="cursor-pointer"
                >
                  <ContextMenu>
                    <ContextMenuButton
                      :label="$t('button.delete')"
                      icon-left="fl:trash"
                      @click="openRemoveDialog(props.row.id)"
                    />
                  </ContextMenu>
                </q-icon>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>

    <AttentionDialog
      :is-open="showRemoveDialog"
      :title="$t('title.attention')"
      @close="showRemoveDialog = true"
    >
      <div class="row justify-center">
        <div class="col-8">
          <div class="text-19-400 text-center q-py-lg q-gutter-y-lg">
            <div>
              {{ $t('text.removeAffiliate') }}
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <AppButton
          class="col-4"
          :label="$t('button.no')"
          @click="showRemoveDialog = false"
        />
        <AppButton
          icon-right="fl:trash"
          class="col"
          color="white"
          text-color="black"
          :label="$t('button.delete')"
          :loading="loading"
          outline
          @click="remove"
        />
      </template>
    </AttentionDialog>
  </div>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppButton from 'common/components/buttons/AppButton';
import ContextMenu from 'common/components/contextMenu/ContextMenu';
import ContextMenuButton from 'common/components/contextMenu/ContextMenuButton';
import AttentionDialog from 'common/components/dialogs/AttentionDialog';
import handleError from 'common/helpers/handleError';
import { copyCodeMixin } from 'common/mixins';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AffiliatesPage',
  components: {
    AppCard,
    AttentionDialog,
    AppButton,
    ContextMenu,
    ContextMenuButton,
    AppInput,
  },
  mixins: [copyCodeMixin],
  data() {
    return {
      columns: [
        {
          name: 'mail', label: this.$t('label.mail'), align: 'left',
        },
        {
          name: 'id', align: 'left', label: this.$t('label.id'),
        },
        {
          name: 'user', align: 'left', label: this.$t('label.user'),
        },
        {
          name: 'time', align: 'left', label: this.$t('label.time'),
        },
        { name: 'actions', align: 'left' },
      ],
      showRemoveDialog: false,
      idForRemove: undefined,
      loading: false,
      query: undefined,
      showSearch: false,
    };
  },
  computed: {
    ...mapState('app', ['account']),
    ...mapState('dashboard', ['affiliates']),
    affiliatesData() {
      return !this.query || !this.showSearch ? this.affiliates : this.affiliates.filter((item) => item.email.current.includes(this.query));
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions('dashboard', ['fetchAffiliates', 'removeAffiliate']),
    async fetch() {
      try {
        this.$q.loading.show();
        await this.fetchAffiliates();
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
    async remove() {
      try {
        this.loading = true;
        await this.removeAffiliate(this.idForRemove);
        this.showRemoveDialog = false;
      } catch (error) {
        handleError(error);
      } finally {
        this.loading = false;
      }
    },
    openRemoveDialog(id) {
      this.idForRemove = id;
      this.showRemoveDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$height: 60px;

.background {
  background-color: $grey-3;
  width: 100%;
  height: 500px;
  border-radius: 20px;
}

.content {
  top: 0;
  left: 0;
}

.dash-affiliate-id {
  min-width: 400px;
  border: 1px solid $primary;
  border-radius: 14px;
  ::v-deep .q-card__section i {
    color: black !important;
  }
}

.table-top {
  height: $height;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border: 1px solid $grey-2;
  border-bottom: none;
}

.table {
  box-shadow: none;
  border: 1px solid $grey-2;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;

  .actions {
    width: 50px;
  }

  ::v-deep {
    th:first-child {
      padding-left: 30px;
    }
    tr {
      height: $height;
    }
  }
}
</style>
