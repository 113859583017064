import { render, staticRenderFns } from "./AffiliatesPage.vue?vue&type=template&id=4e0bff8e&scoped=true&"
import script from "./AffiliatesPage.vue?vue&type=script&lang=js&"
export * from "./AffiliatesPage.vue?vue&type=script&lang=js&"
import style0 from "./AffiliatesPage.vue?vue&type=style&index=0&id=4e0bff8e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e0bff8e",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QTable,QTr,QTd,QCard});
